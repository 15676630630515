import React from "react";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { QRCode } from "@/shared/components/QRCode";
import { CopyAddressButton } from "@/shared/components/CopyAddressButton";
import { useToggler } from "@/shared/hooks/useToggler";
import { MoneySentPopup } from "@/pages/SubscriptionPage/components/ManeySentPopup";
export var AddFunds = function (_a) {
    var selectedCurrency = _a.selectedCurrency, selectedBlockchain = _a.selectedBlockchain, address = _a.address;
    var moneySentPopup = useToggler();
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { padding: "32px", bgColor: "general-white", borderRadius: "32px", marginTop: "32px" },
            React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "24px" },
                React.createElement(Typography, { type: "text24", sx: { display: "flex", alignItems: "center" }, gap: "8px" },
                    "Your",
                    React.createElement(TokenIcon, { symbol: selectedCurrency === null || selectedCurrency === void 0 ? void 0 : selectedCurrency.slug, iconUrl: selectedCurrency === null || selectedCurrency === void 0 ? void 0 : selectedCurrency.iconUrl, size: 24 }), selectedCurrency === null || selectedCurrency === void 0 ? void 0 :
                    selectedCurrency.description,
                    " address"),
                address && (React.createElement(Box, { display: "flex", flexDirection: "column", alignItems: "center", gap: "16px" },
                    React.createElement(QRCode, { value: address, size: 160 }),
                    React.createElement(CopyAddressButton, { address: address }))),
                React.createElement(Box, { padding: "24px", bgColor: "black-50", width: "100%", borderRadius: "16px", boxSizing: "border-box" },
                    React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" },
                        React.createElement(Typography, { type: "text14", sx: {
                                color: "#80889C",
                            } }, "Network"),
                        React.createElement(Typography, { type: "text16" }, selectedBlockchain === null || selectedBlockchain === void 0 ? void 0 : selectedBlockchain.name)),
                    React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "20px" },
                        React.createElement(Typography, { type: "text14", sx: {
                                color: "#80889C",
                            } }, "Minimum"),
                        React.createElement(Typography, { type: "text16" }, "***"))),
                React.createElement(Box, { padding: "24px", bgColor: "black-50", width: "100%", borderRadius: "16px", boxSizing: "border-box" },
                    React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
                        React.createElement(BaseIcon, { icon: "WarningOrange", size: 44 }),
                        React.createElement(Typography, { color: "black-500", type: "text14" },
                            "Send only ", selectedCurrency === null || selectedCurrency === void 0 ? void 0 :
                            selectedCurrency.shortName,
                            " to this address. Sending other assets will result in permanent loss. If in doubt, receive a small amount first"))))),
        React.createElement(Box, { display: "flex", justifyContent: "center", marginTop: "32px" },
            React.createElement(Button, { size: "l", sx: { width: "280px" }, onClick: moneySentPopup.on }, "Money was sent")),
        moneySentPopup.enabled && React.createElement(MoneySentPopup, null)));
};
