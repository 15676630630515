import React from "react";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { useParams } from "react-router-dom";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { BankAccountListStore } from "@/shared/stores/BankAccountListStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { observer } from "mobx-react-lite";
import { useBack } from "@/shared/hooks/useBack";
import { PaymentType, TokenAmountInput, } from "@/shared/components/TokenAmountInput";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { ExchangeType } from "@/routes";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { BankTransferPageStore } from "@/pages/BankTransferPage/stores/BankTransferPageStore";
import { runInAction } from "mobx";
import { Input } from "@/shared/components/Input";
import { ConfirmPopup } from "@/features/transfer/components/ConfirmPopup";
import { formattedTokenAmount } from "@broxus/js-utils";
import { SuccessPopup } from "@/features/transfer/components/SuccessPopup";
import { FailPopup } from "@/features/transfer/components/FailPopup";
export var BankTransferPage = observer(function () {
    var _a, _b, _c, _d, _e;
    var back = useBack();
    var params = useParams();
    var accountId = params.id;
    var bankAccounts = useStoreContext(BankAccountListStore);
    var currencies = useStoreContext(CurrencyListStore);
    var balances = useStoreContext(BalanceListStore);
    var accounts = useStoreContext(AccountListStore);
    var bankTransfer = useStore(BankTransferPageStore);
    var bankAccount = bankAccounts.list.find(function (acc) { return acc.id === accountId; });
    React.useEffect(function () {
        balances.sync.apply(balances, accounts.list.map(function (acc) { return acc.account; }));
    }, [bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.id, accounts.list.length, currencies.list.length]);
    var onCurrencyChange = function (slug) {
        var currency = currencies.list.find(function (c) { return c.slug === slug; });
        if (currency) {
            runInAction(function () {
                bankTransfer.fromCurrency = currency;
            });
        }
    };
    var onAmountChange = function (amount) {
        runInAction(function () {
            bankTransfer.amount = amount;
        });
    };
    React.useEffect(function () {
        if (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.currency[0]) {
            onCurrencyChange(bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.currency[0]);
        }
    }, [bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.id, currencies.list.length]);
    React.useEffect(function () {
        var accountId = bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.account_id;
        if (accountId) {
            runInAction(function () {
                bankTransfer.fromAccount = accountId;
            });
        }
    }, [bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.account_id]);
    React.useEffect(function () {
        bankTransfer.init();
    }, [bankTransfer]);
    var info = [
        {
            label: "Amount",
            value: ((_a = bankTransfer.feeData.value) === null || _a === void 0 ? void 0 : _a.data.amount) &&
                bankTransfer.fromAccount
                ? "".concat(formattedTokenAmount((_c = (_b = bankTransfer.feeData.value) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.amount), " ").concat(bankTransfer.fromCurrency)
                : "–",
        },
        {
            label: "Transaction fee",
            value: bankTransfer.fee
                ? formattedTokenAmount(bankTransfer.fee)
                : "–",
        },
        {
            label: "Processing time",
            value: "Instantly",
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
            React.createElement(Box, { display: "flex", gap: "8px", alignItems: "center" },
                React.createElement(BaseIcon, { icon: "Back", onClick: back, size: 24 }),
                React.createElement(Typography, { type: "text28Bold" }, "Transfer")),
            React.createElement(Box, { padding: "32px", display: "flex", flexDirection: "column", gap: "32px", bgColor: "general-white", borderRadius: "32px" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "24px" },
                    React.createElement(Box, { padding: "16px", display: "flex", gap: "8px", bgColor: "black-50", borderRadius: "16px" },
                        React.createElement(BaseIcon, { icon: "Info", color: "black-500" }),
                        React.createElement(Typography, { color: "black-500", type: "text14" }, "You can transfer money only to yourself in another bank"))),
                bankTransfer.fromCurrency && (React.createElement(TokenAmountInput, { amount: bankTransfer.amount, balances: balances.data, currency: bankTransfer.fromCurrency, onCurrencyChange: onCurrencyChange, onAmountChange: onAmountChange, onSetAll: function () { }, type: ExchangeType.Sell, initialPaymentType: PaymentType.ACCOUNTS, accountsOnly: true })),
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "24px" },
                    React.createElement(Typography, { type: "text16Bold" }, "Recipient details"),
                    React.createElement(Input, { label: "IBAN", size: "l", value: bankTransfer.iban, onChange: function (event) {
                            runInAction(function () {
                                bankTransfer.iban = event.target.value;
                            });
                        } }),
                    React.createElement(Input, { label: "BIC or SWIFT code", size: "l", value: bankTransfer.bicOrSwiftCode, onChange: function (event) {
                            runInAction(function () {
                                bankTransfer.bicOrSwiftCode =
                                    event.target.value;
                            });
                        } }))),
            React.createElement(Button, { disabled: !bankTransfer.isValid, size: "l", onClick: bankTransfer.showConfirmation, loading: bankTransfer.feeData.loading || bankTransfer.loading }, "Transfer")),
        bankTransfer.confirmation && (React.createElement(ConfirmPopup, { info: info, isLoading: bankTransfer.loading, onClose: bankTransfer.hideConfirmation, onSubmit: bankTransfer.transfer, amount: bankTransfer.total, currency: (_d = bankTransfer.fromCurrency) === null || _d === void 0 ? void 0 : _d.slug })),
        bankTransfer.result && (React.createElement(SuccessPopup, { minus: true, title: "You've successfully sent funds", address: bankTransfer.iban, amount: bankTransfer.amount, createdAt: bankTransfer.result.createdAt, currency: bankTransfer.result.currency, onClose: bankTransfer.reset })),
        !!bankTransfer.submitErrors.length && (React.createElement(FailPopup, { message: (_e = bankTransfer.submitErrors.at(0)) === null || _e === void 0 ? void 0 : _e.description, onClose: bankTransfer.hideErrors, onSubmit: bankTransfer.showConfirmation }))));
});
