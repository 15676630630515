import { ButtonBase } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormAlert } from "../FormAlert";
var sizeToHeight = {
    xxs: "16px",
    xs: "32px",
    s: "40px",
    icon: "46px",
    m: "40px",
    l: "56px",
};
export var sizeIcon = {
    xxs: 12,
    xs: 16,
    s: 24,
    icon: 24,
    m: 24,
    l: 24,
};
export var padding = {
    xxs: "0 6px",
    xs: "0 12px",
    s: "0 12px",
    icon: "0 10px",
    m: "0 12px",
    l: "0 40px",
};
export var paddingWithIcon = {
    xxs: "0 6px",
    xs: "0 8px",
    s: "0 12px",
    icon: "0 10px",
    m: "0 12px",
    l: "0 16px",
};
export var paddingIcon = {
    xxs: "0 2px",
    xs: "0 8px",
    s: "0 8px",
    icon: "0 10px",
    m: "0 12px",
    l: "0 16px",
};
export var StyledButton = styled(ButtonBase, {
    shouldForwardProp: function (prop) { return !prop.startsWith("$"); },
})(function (_a) {
    var $size = _a.$size, $width = _a.$width, $minWidth = _a.$minWidth, $padding = _a.$padding, theme = _a.theme;
    return ({
        borderRadius: "16px",
        height: sizeToHeight[$size],
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "4px",
        transition: "background 0.2s ease, opacity 0.2s ease",
        width: $width || "100%",
        minWidth: $minWidth,
        padding: $padding,
        zIndex: 0,
        flexShrink: 0,
        position: "relative",
        "&.ButtonIcon": {
            height: $size === "xxs" ? "16px" : sizeToHeight[$size],
        },
        "&.Button-Default": {
            background: theme.colors["primary"],
            color: theme.colors["general-white"],
            "&:hover:not(.Mui-disabled)": {
                color: theme.colors["general-white"],
                background: theme.colors["secondary"],
                "& .MuiTypography-root": {
                    color: theme.colors["general-white"],
                },
            },
        },
        "&.Button-Primary": {
            background: theme.colors["black-1000"],
            "&:hover:not(.Mui-disabled)": {
                background: "rgba(25, 27, 32, 1)",
            },
        },
        "&.Button-Transparent": {
            background: "transparent",
        },
        "&.Button-White": {
            background: theme.colors["general-white"],
            "&:hover:not(.Mui-disabled)": {
                background: theme.colors["general-white"],
            },
        },
        "&.Button-Black": {
            background: theme.colors["black-1000"],
            "&:hover:not(.Mui-disabled)": {
                background: theme.colors["black-1200"],
            },
        },
        "&.Button-Gray": {
            background: theme.colors["black-100"],
            "&:hover:not(.Mui-disabled)": {
                background: theme.colors["black-200"],
            },
        },
        "&.Button-Bordered": {
            background: theme.colors["general-white"],
            boxShadow: "inset 0 0 0 1px ".concat(theme.colors["black-200"]),
            "&:hover:not(.Mui-disabled)": {
                background: theme.colors["general-white"],
            },
        },
        "&.Button-BlueBordered": {
            background: theme.colors["general-white"],
            boxShadow: "inset 0 0 0 1px ".concat(theme.colors["deep-blue-500"]),
            "&:hover:not(.Mui-disabled)": {
                background: theme.colors["general-white"],
            },
        },
        "&.Button-LightBlue": {
            background: theme.colors["secondary"],
            "& .MuiTypography-root": {
                color: theme.colors["general-white"],
            },
            "&:hover:not(.Mui-disabled)": {
                background: theme.colors["primary"],
                "& .MuiTypography-root": {
                    color: theme.colors["secondary"],
                },
            },
        },
        "&.ButtonDisabled": {
            cursor: "not-allowed",
            pointerEvents: "auto",
            background: theme.colors["black-100"],
        },
        "&.Button-Success": {
            background: "#28D1801A",
            "&:hover:not(.Mui-disabled)": {
                background: "#28D1801A",
            },
        },
    });
});
export var StyledAlert = styled(FormAlert)(function () { return ({
    position: "absolute",
    bottom: "calc(100% + 10px)",
    boxSizing: "border-box",
    width: "100%",
    zIndex: 2,
    pointerEvents: "none",
}); });
