import React from "react";
import { formattedTokenAmount } from "@broxus/js-utils";
import { observer } from "mobx-react-lite";
import { generatePath, useNavigate } from "react-router-dom";
import { Box, Typography } from "@/shared/components";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { useStoreContext } from "@/shared/hooks/useStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { ROUTES } from "@/routes";
export var Account = observer(function (_a) {
    var _b, _c;
    var account = _a.account;
    var navigate = useNavigate();
    var currencyList = useStoreContext(CurrencyListStore);
    var balanceList = useStoreContext(BalanceListStore);
    var accountList = useStoreContext(AccountListStore);
    React.useEffect(function () {
        balanceList.sync.apply(balanceList, accountList.list.map(function (acc) { return acc.account; }));
    }, [balanceList, currencyList.list.length, accountList.list.length]);
    var accountCurrency = account.currency[0].toLowerCase();
    return (React.createElement(Box, { height: 96, padding: "24px 20px", display: "flex", gap: "8px", borderRadius: "22px", bgColor: "general-white", boxSizing: "border-box", sx: {
            cursor: "pointer",
        }, onClick: function () {
            navigate(generatePath(ROUTES.bankAccountsItem, {
                id: account.id,
            }));
        } },
        React.createElement(Box, { display: "flex", gap: "12px", alignItems: "center" },
            React.createElement(TokenIcon, { iconUrl: (_b = currencyList.list.find(function (c) { return c.slug === accountCurrency; })) === null || _b === void 0 ? void 0 : _b.iconUrl, size: 44 }),
            !(account.status === "INIT" || account.status === "PENDING") && (React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Typography, { type: "text16Bold" }, formattedTokenAmount((_c = balanceList.balances.find(function (value) { return value.account === account.account_id && value.currency.toLowerCase() === accountCurrency; })) === null || _c === void 0 ? void 0 : _c.balance)),
                React.createElement(Typography, { color: "black-500", type: "text14" }, accountCurrency.toUpperCase()))),
            (account.status === "INIT" ||
                account.status === "PENDING") && (React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Typography, { color: "general-orange", type: "text14" }, "Verifying"))),
            account.status === "ACTION_REQUIRED" && (React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Typography, { color: "general-red", type: "text14" }, "Declined"))),
            account.status === "BLOCKED" && (React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Typography, { color: "general-red", type: "text14" }, "Blocked"))),
            account.status === "CLOSED" && (React.createElement(Box, { display: "flex", flexDirection: "column" },
                React.createElement(Typography, { color: "general-red", type: "text14" }, "Closed"))))));
});
