import React from "react";
import { generatePath, Link } from "react-router-dom";
import { Popup } from "@/shared/components/Popup";
import { Box, Button, Typography } from "@/shared/components";
import time from "@/shared/img/time.png";
import { ROUTES } from "@/routes";
export var SuccessPaymentPopup = function (_a) {
    var name = _a.name;
    return (React.createElement(Popup, { width: "480px" },
        React.createElement(Box, { padding: "32px", display: "flex", alignItems: "center", gap: "36px", flexDirection: "column" },
            React.createElement("img", { src: time, alt: "take your time", width: "160px", height: "160px" }),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" },
                React.createElement(Typography, { type: "text28Bold" }, "Hooray!"),
                React.createElement(Typography, { type: "text16", textAlign: "center" },
                    "You are supercharged with our ",
                    name,
                    " plan")),
            React.createElement(Button, { sx: { width: "100%" }, size: "l", LinkComponent: Link, to: generatePath(ROUTES.root) }, "Great"))));
};
