import React from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useProvider, useStore, useStoreContext, } from "@/shared/hooks/useStore";
import { SendCryptoStep, SendCryptoStore, } from "@/pages/SendCryptoPage/stores/SendCryptoStore";
import { ChooseCryptoSend } from "@/pages/SendCryptoPage/components/ChooseCryptoSend";
import { SendCryptoForm } from "@/pages/SendCryptoPage/components/SendCryptoForm";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { runInAction } from "mobx";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
export var SendCryptoPage = observer(function () {
    var currencySlug = useParams().currency;
    var currencyList = useStoreContext(CurrencyListStore);
    var accounts = useStoreContext(AccountListStore);
    var sendCrypto = useStore(SendCryptoStore, accounts);
    React.useEffect(function () {
        if (currencySlug) {
            var currency_1 = currencyList.list.find(function (c) { return c.slug === currencySlug; });
            runInAction(function () {
                sendCrypto.fromCurrency = currency_1;
                sendCrypto.step = SendCryptoStep.Form;
            });
        }
    }, [currencySlug, currencyList.list]);
    var SendCryptoProvider = useProvider(SendCryptoStore);
    return (React.createElement(SendCryptoProvider, { value: sendCrypto }, sendCrypto.step === SendCryptoStep.ChooseCrypto ? (React.createElement(ChooseCryptoSend, null)) : (React.createElement(SendCryptoForm, null))));
});
