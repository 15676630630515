import React from "react";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import { formattedTokenAmount } from "@broxus/js-utils";
import { Box, Button } from "@/shared/components";
import { PageHeader } from "@/shared/components/PageHeader";
import { TokenAmountInput } from "@/shared/components/TokenAmountInput";
import { useStore, useStoreContext } from "@/shared/hooks/useStore";
import { SendCryptoStep, SendCryptoStore } from "@/pages/SendCryptoPage/stores/SendCryptoStore";
import { BalanceListStore } from "@/shared/stores/BalanceListStore";
import { ExchangeType, ROUTES } from "@/routes";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { WalletAddressInput } from "@/shared/components/WalletAddressInput";
import { ConfirmPopup } from "@/features/transfer/components/ConfirmPopup";
import { SuccessPopup } from "@/features/transfer/components/SuccessPopup";
import { FailPopup } from "@/features/transfer/components/FailPopup";
import BigNumber from "bignumber.js";
import { generatePath, useNavigate } from "react-router-dom";
import { useToggler } from "@/shared/hooks/useToggler";
import { SelectBlockchainModal } from "@/shared/components/SelectBlockchainModal";
export var SendCryptoForm = observer(function () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    var selectBlockchainModal = useToggler();
    var navigate = useNavigate();
    var sendCrypto = useStoreContext(SendCryptoStore);
    var accountList = useStoreContext(AccountListStore);
    var currencyList = useStoreContext(CurrencyListStore);
    var balances = useStore(BalanceListStore, currencyList);
    var _k = React.useReducer(function (x) { return x + 1; }, 0), forceUpdate = _k[1];
    React.useEffect(function () {
        if (currencyList.list.length === 0) {
            currencyList.syncAll();
        }
    }, [currencyList.list.length]);
    React.useEffect(function () {
        if (sendCrypto.fromCurrency) {
            var tokens_1 = sendCrypto.fromCurrency.tokens;
            if (tokens_1) {
                if (tokens_1.length === 1) {
                    runInAction(function () {
                        sendCrypto.fromBlockchain = tokens_1[0].blockchain;
                        sendCrypto.step = SendCryptoStep.Form;
                    });
                }
                if (tokens_1.length > 1) {
                    selectBlockchainModal.on();
                }
            }
        }
    }, [sendCrypto.fromCurrency]);
    React.useEffect(function () {
        balances.sync.apply(balances, accountList.list.map(function (acc) { return acc.account; }));
    }, [accountList.list, balances]);
    var onAmountChange = function (amount) {
        runInAction(function () {
            sendCrypto.amount = amount;
        });
    };
    var onSetAll = function () {
        runInAction(function () {
            var _a, _b;
            sendCrypto.amount =
                (_b = balances.data[(_a = sendCrypto.fromCurrency) === null || _a === void 0 ? void 0 : _a.slug]) === null || _b === void 0 ? void 0 : _b.balance;
        });
    };
    var onCurrencyChange = function (currency) {
        var fromCurrency = currencyList.list.find(function (c) { return c.slug === currency; });
        if (fromCurrency) {
            navigate(generatePath(ROUTES.sendCrypto, {
                currency: fromCurrency.slug,
            }), { replace: true });
        }
        sendCrypto.fromCurrency = fromCurrency;
    };
    var onWalletAddressChange = function (value) {
        runInAction(function () {
            sendCrypto.walletAddress = value;
        });
    };
    var onBlockchainSelect = function (blockchain) {
        runInAction(function () {
            sendCrypto.fromBlockchain = blockchain;
        });
        selectBlockchainModal.off();
    };
    var info = [
        {
            label: "Fee amount",
            value: ((_a = sendCrypto.feeData.value) === null || _a === void 0 ? void 0 : _a.data.amount) && sendCrypto.fromCurrency
                ? "".concat(formattedTokenAmount(sendCrypto.feeData.value.data.amount), " ").concat((_b = sendCrypto.fromCurrency.shortName) === null || _b === void 0 ? void 0 : _b.toUpperCase())
                : "–",
        },
        {
            label: "Processing time",
            value: "Instantly",
        },
    ];
    var balance = balances.data[(_d = (_c = sendCrypto.fromCurrency) === null || _c === void 0 ? void 0 : _c.slug) !== null && _d !== void 0 ? _d : ""];
    var enoughFunds = balance
        ? new BigNumber(sendCrypto.amount).lte(balance.balance)
        : false;
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
            React.createElement(PageHeader, { title: "Send ".concat((_e = sendCrypto.fromCurrency) === null || _e === void 0 ? void 0 : _e.shortName) }),
            React.createElement(Box, { bgColor: "general-white", borderRadius: "32px", padding: "32px", display: "flex", flexDirection: "column", gap: "8px" },
                sendCrypto.fromCurrency && (React.createElement(TokenAmountInput, { currency: sendCrypto.fromCurrency, amount: sendCrypto.amount, balances: balances.data, onCurrencyChange: onCurrencyChange, onAmountChange: onAmountChange, type: ExchangeType.Sell, onSetAll: onSetAll })),
                React.createElement(WalletAddressInput, { value: sendCrypto.walletAddress, onChange: onWalletAddressChange })),
            React.createElement(Box, { display: "flex", justifyContent: "center" },
                React.createElement(Button, { size: "l", sx: {
                        maxWidth: "300px",
                        width: "100%",
                    }, loading: sendCrypto.feeData.loading || sendCrypto.loading, onClick: sendCrypto.showConfirmation, disabled: !enoughFunds ||
                        sendCrypto.walletAddress.length === 0 ||
                        new BigNumber(sendCrypto.amount).isNaN() ||
                        new BigNumber(sendCrypto.amount).isEqualTo(0) }, "Review order"))),
        sendCrypto.confirmation && (React.createElement(ConfirmPopup, { info: info, isLoading: sendCrypto.loading, onClose: sendCrypto.hideConfirmation, onSubmit: sendCrypto.submit, amount: sendCrypto.amount, currency: (_f = sendCrypto.fromCurrency) === null || _f === void 0 ? void 0 : _f.shortName, to: sendCrypto.walletAddress })),
        sendCrypto.result && (React.createElement(SuccessPopup, { minus: true, title: "You've successfully sent the crypto", address: sendCrypto.walletAddress, amount: sendCrypto.total, createdAt: (_g = sendCrypto.result) === null || _g === void 0 ? void 0 : _g.createdAt, currency: (_h = sendCrypto.result) === null || _h === void 0 ? void 0 : _h.currency, onClose: sendCrypto.reset })),
        !!sendCrypto.submitErrors.length && (React.createElement(FailPopup, { message: (_j = sendCrypto.submitErrors.at(0)) === null || _j === void 0 ? void 0 : _j.description, onClose: sendCrypto.hideErrors, onSubmit: sendCrypto.showConfirmation })),
        sendCrypto.fromCurrency && selectBlockchainModal.enabled && (React.createElement(SelectBlockchainModal, { blockchainList: sendCrypto.availableBlockchains, onClose: selectBlockchainModal.off, onBlockchainSelect: onBlockchainSelect }))));
});
