import React from "react";
import { Box, Button, Typography } from "@/shared/components";
import { Popup } from "@/shared/components/Popup";
import { PopupBody } from "@/shared/components/Popup/Body";
import { PopupContent } from "@/shared/components/Popup/Content";
import { PopupHead } from "@/shared/components/Popup/Head";
import { PopupTitle } from "@/shared/components/Popup/Title";
import { sliceAddress } from "@/shared/utils/slice-address";
export var ConfirmPopup = function (_a) {
    var onSubmit = _a.onSubmit, onClose = _a.onClose, info = _a.info, isLoading = _a.isLoading, amount = _a.amount, currency = _a.currency, to = _a.to;
    return (React.createElement(Popup, { width: "450px", onClose: onClose },
        React.createElement(PopupHead, { onClose: onClose }),
        React.createElement(PopupBody, null,
            React.createElement(PopupTitle, { type: "text30Bold" },
                "You will send ",
                amount,
                " ",
                currency,
                " to",
                " ",
                React.createElement(Typography, { color: "deep-blue-500", type: "text32Bold" }, to ? sliceAddress(to) : "\u200b")),
            React.createElement(PopupContent, { justifyContent: "flex-end" },
                React.createElement(Box, { display: "flex", flexDirection: "column", gap: "30px" },
                    React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }),
                    React.createElement(Box, { display: "flex", flexDirection: "column", gap: "10px" }, info.map(function (_a) {
                        var label = _a.label, value = _a.value;
                        return (React.createElement(Box, { key: value, display: "flex", alignItems: "center", justifyContent: "space-between" },
                            React.createElement(Typography, { color: "black-500" }, label),
                            React.createElement(Typography, null, value)));
                    })),
                    React.createElement(Box, { width: "100%", height: "1px", bgColor: "black-100" }))),
            React.createElement(Button, { width: "100%", onClick: onSubmit, loading: isLoading, disabled: isLoading }, "Continue"))));
};
