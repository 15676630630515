import React from 'react';
import { Navigate } from 'react-router-dom';
import { useStoreContext } from "@/shared/hooks/useStore";
import { AuthStore } from "@/shared/stores/AuthStore";
var ScopedRoute = function (_a) {
    var children = _a.children, requiredScopes = _a.requiredScopes, redirectPath = _a.redirectPath;
    var auth = useStoreContext(AuthStore);
    var hasAccess = requiredScopes === null || requiredScopes === void 0 ? void 0 : requiredScopes.every(function (scope) { var _a; return (_a = auth.userInfo) === null || _a === void 0 ? void 0 : _a.userScopes.includes(scope); });
    return hasAccess ? (children) : (React.createElement(Navigate, { to: redirectPath }));
};
export default ScopedRoute;
