import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { generatePath } from "react-router-dom";
import { SigninPageStore } from "@/pages/SigninPage/stores/SigninPageStore";
import { ROUTES } from "@/routes";
import { Box, FormControlInput } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { Link } from "@/shared/components/Link";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
export var PasswordForm = observer(function () {
    var _a, _b;
    var page = useStoreContext(SigninPageStore);
    return (React.createElement(AuthForm, { onBack: page.reset, onSubmit: page.submit, title: "Sign in", desc: React.createElement(React.Fragment, null,
            "Do not have an account?",
            " ",
            React.createElement(Link, { type: "text16Bold", to: generatePath(ROUTES.root) }, "Create an account")), content: React.createElement(React.Fragment, null,
            React.createElement(Box, null,
                React.createElement(FormControlInput, { type: "password", label: "Password", error: (_a = page.errorsByKey.password) === null || _a === void 0 ? void 0 : _a[0], variantError: "alert", disabled: page.loading, value: page.password, onChange: onChange(action(function (value) {
                        page.password = value;
                    })) }),
                React.createElement(Box, { marginTop: "8px" },
                    React.createElement(Link, { color: "blue-500", sx: { fontSize: "14px" }, to: generatePath(ROUTES.passwordReset) }, "Forgot password?"))),
            React.createElement(Button, { type: "submit", width: "100%", error: (_b = page.errorsByKey.unknown) === null || _b === void 0 ? void 0 : _b[0], size: "l", 
                // disabled={!page.isEmailValid || !page.isPasswordValid || page.loading}
                disabled: !page.isPasswordValid || page.loading }, "Sign in")) }));
});
