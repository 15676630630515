var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Popover } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import React from "react";
import { generatePath, Link, NavLink } from "react-router-dom";
import { MenuLinks } from "../../constants";
import { ROUTES } from "@/routes";
import { BaseIcon, Box, Typography } from "@/shared/components";
export var Menu = function () { return (React.createElement(Box, { position: "sticky", top: "0" },
    React.createElement(Box, { position: "absolute", top: "0", left: "0" },
        React.createElement(Container, null,
            React.createElement(Logo, { to: generatePath(ROUTES.root) },
                React.createElement(BaseIcon, { icon: "LogoSmall", size: 36 })),
            React.createElement(List, null, MenuLinks.map(function (props) { return (React.createElement(MenuItem, __assign({ key: props.label }, props))); })))))); };
var MenuItem = function (_a) {
    var link = _a.link, icon = _a.icon, label = _a.label;
    var theme = useTheme();
    var _b = React.useState(null), anchorEl = _b[0], setAnchorEl = _b[1];
    var handlePopoverOpen = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handlePopoverClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    return (React.createElement(React.Fragment, null,
        React.createElement(Item, { to: generatePath(link), onMouseEnter: handlePopoverOpen, onMouseLeave: handlePopoverClose },
            React.createElement(BaseIcon, { icon: icon, size: 22 })),
        React.createElement(Popover, { sx: {
                pointerEvents: "none",
            }, open: open, anchorEl: anchorEl, anchorOrigin: {
                vertical: "center",
                horizontal: "right",
            }, transformOrigin: {
                vertical: "center",
                horizontal: "left",
            }, onClose: handlePopoverClose, disableRestoreFocus: true, slotProps: {
                paper: {
                    sx: {
                        background: theme.colors["black-600"],
                        padding: "5px 10px",
                        boxSizing: "border-box",
                        margin: "0 0 0 4px",
                    },
                },
            } },
            React.createElement(Typography, { type: "text12", color: "general-white" }, label))));
};
var Container = styled("div")(function () { return ({
    width: 76,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "18px 0",
    gap: 40,
}); });
var Logo = styled(Link)(function () { return ({
    width: 36,
    height: 36,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
}); });
var List = styled("div")(function () { return ({
    display: "flex",
    alignItems: "center",
    gap: 16,
    flexDirection: "column",
}); });
var Item = styled(NavLink)(function (_a) {
    var theme = _a.theme;
    return ({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 46,
        height: 46,
        color: theme.colors["black-300"],
        borderRadius: 12,
        transition: "0.2s ease",
        "&:hover": {
            color: theme.colors["black-400"],
            background: theme.colors["black-50"],
        },
        "&.active": {
            background: theme.colors["primary"],
            color: theme.colors["general-white"],
        },
    });
});
