import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { PhonePageStore, } from "@/pages/PhonePage/stores/PhonePageStore";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { PhoneInput } from "@/shared/components/FormControlInput/PhoneInput";
import { useStoreContext } from "@/shared/hooks/useStore";
export var PhoneForm = observer(function () {
    var _a;
    var page = useStoreContext(PhonePageStore);
    var handleSubmit = function () {
        page.submitPhone();
    };
    return (React.createElement(AuthForm, { onSubmit: handleSubmit, title: "Your phone number", content: React.createElement(React.Fragment, null,
            React.createElement(PhoneInput, { value: page.phone, error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description, disabled: page.loading, onChange: action(function (value) {
                    page.phone = value;
                }) }),
            React.createElement(Button, { type: "submit", width: "100%", disabled: page.loading || !page.isPhoneValid, sx: {
                    borderRadius: "16px",
                } }, "Continue")) }));
});
