import { observer } from "mobx-react-lite";
import * as React from "react";
import { SignupPageStore } from "@/pages/SignupPage/stores/SignupPageStore";
import { Box, Typography } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { useStoreContext } from "@/shared/hooks/useStore";
import { EmailCodeInput } from "@/pages/SignupPage/components/EmailCodeInput";
export var EmailCodeForm = observer(function () {
    var page = useStoreContext(SignupPageStore);
    return (React.createElement(AuthForm, { onBack: page.resetEmail, onSubmit: page.submitEmailCode, title: "5-digit code", desc: React.createElement(React.Fragment, null,
            "We emailed you a code to",
            " ",
            React.createElement(Typography, { color: "deep-blue-500" }, page.email),
            "."), content: React.createElement(Box, { display: "flex", alignItems: "center", flexDirection: "column" },
            React.createElement(EmailCodeInput, { onComplete: page.submitEmailCode }),
            React.createElement(Box, { marginTop: "24px", display: "flex", alignItems: "center", flexDirection: "column" },
                React.createElement(Box, null,
                    React.createElement(Typography, { type: "text14", color: "deep-blue-500", onClick: function () {
                            page.submitPassword();
                        } }, "Resend code")),
                React.createElement(Box, null,
                    React.createElement(Typography, { type: "text14", color: "deep-blue-500", onClick: function () {
                            // @ts-ignore
                            window.zE("webWidget", "open");
                        } }, "No code received?")))) }));
});
