import React from "react";
import { observer } from "mobx-react-lite";
import { TotalBalance } from "@/pages/HomePage/components/TotalBalance";
import { BalanceType } from "@/shared/stores/BalanceTypeStore";
import { BaseIcon, Box, Button, Typography } from "@/shared/components";
import { TokenIcon } from "@/shared/components/TokenIcon";
import { useBack } from "@/shared/hooks/useBack";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useStoreContext } from "@/shared/hooks/useStore";
import { BankAccountListStore } from "@/shared/stores/BankAccountListStore";
import { CurrencyListStore } from "@/shared/stores/CurrencyListStore";
import { generateQueryPath } from "@/shared/utils/path";
import { ROUTES } from "@/routes";
import { AccountDetailsPopup } from "@/pages/HomePage/components/Accounts/components/AccountDetailsPopup";
import { useToggler } from "@/shared/hooks/useToggler";
export var BankAccountPage = observer(function () {
    var back = useBack();
    var navigate = useNavigate();
    var accountDetailsPopup = useToggler();
    var params = useParams();
    var accountId = params.id;
    var bankAccounts = useStoreContext(BankAccountListStore);
    var currencies = useStoreContext(CurrencyListStore);
    var account = bankAccounts.list.find(function (acc) { return acc.id === accountId; });
    var currency = currencies.list.find(function (c) { return c.slug === (account === null || account === void 0 ? void 0 : account.currency[0].toLowerCase()); });
    var handleAccountDetailsPopupClose = function () {
        accountDetailsPopup.off();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { display: "flex", flexDirection: "column", gap: "32px" },
            React.createElement(Box, { display: "flex", gap: "8px", alignItems: "center" },
                React.createElement(BaseIcon, { icon: "Back", onClick: back, size: 24 }),
                React.createElement(TokenIcon, { iconUrl: currency === null || currency === void 0 ? void 0 : currency.iconUrl, size: 32 }),
                React.createElement(Typography, { type: "text28Bold" }, currency === null || currency === void 0 ? void 0 : currency.shortName)),
            React.createElement(Box, { display: "flex", flexDirection: "column", gap: "16px" },
                React.createElement(TotalBalance, { type: BalanceType.Fiat }),
                React.createElement(Box, { display: "flex", gap: "16px" },
                    React.createElement(Button, { sx: {
                            maxWidth: "120px",
                            width: "100%",
                        }, onClick: accountDetailsPopup.on }, "Add money"),
                    React.createElement(Button, { variant: "LightBlue", sx: {
                            maxWidth: "120px",
                            width: "100%",
                        }, onClick: function () {
                            navigate(generateQueryPath(ROUTES.exchange, { type: "sell" }, {
                                from: currency === null || currency === void 0 ? void 0 : currency.slug,
                                to: "usdt",
                            }));
                        } }, "Exchange"),
                    React.createElement(Button, { variant: "LightBlue", sx: {
                            maxWidth: "120px",
                            width: "100%",
                        }, onClick: function () {
                            navigate(generatePath(ROUTES.bankTransfer, {
                                id: account === null || account === void 0 ? void 0 : account.id,
                            }));
                        } }, "Transfer")))),
        account && accountDetailsPopup.enabled && (React.createElement(AccountDetailsPopup, { account: account, onClose: handleAccountDetailsPopupClose }))));
});
