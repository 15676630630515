import { action } from "mobx";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { PhonePageStore } from "@/pages/PhonePage/stores/PhonePageStore";
import { BaseIcon, Box, FormControlInput } from "@/shared/components";
import { AuthForm } from "@/shared/components/AuthForm";
import { Button } from "@/shared/components/Button";
import { useStoreContext } from "@/shared/hooks/useStore";
import { onChange } from "@/shared/utils/input";
import { ApplyReferralCodeButton } from "@/pages/PhonePage/components/ApplyReferralCodeButton";
export var ReferralCodeForm = observer(function () {
    var _a;
    var page = useStoreContext(PhonePageStore);
    return (React.createElement(AuthForm, { onBack: page.resetPhone, onSubmit: page.submit, title: "Referral code", content: React.createElement(React.Fragment, null,
            React.createElement(FormControlInput, { label: "Your code", type: "text", value: page.referralCode, onChange: onChange(action(function (value) {
                    page.referralCode = value;
                })), disabled: page.referralCodeApplied || page.loading, variantError: "alert", error: (_a = page.errors[0]) === null || _a === void 0 ? void 0 : _a.description, InputProps: {
                    endAdornment: (React.createElement(Box, { display: "flex", alignItems: "center" },
                        page.referralCode.length > 0 &&
                            !page.referralCodeApplied && (React.createElement(BaseIcon, { icon: "Close", size: 36, onClick: function () {
                                page.referralCode = "";
                            } })),
                        React.createElement(ApplyReferralCodeButton, { applied: page.referralCodeApplied, onSubmit: page.submitReferralCode }))),
                } }),
            React.createElement(Button, { type: "submit", width: "100%", disabled: page.loading, sx: { borderRadius: "16px" } }, "Continue")) }));
});
