import { observer } from "mobx-react-lite";
import * as React from "react";
import { formattedTokenAmount } from "@broxus/js-utils";
import { Box, Button, Typography } from "@/shared/components";
import { useCsv } from "@/shared/hooks/useCsv";
import { useDownload } from "@/shared/hooks/useDownload";
import { useStoreContext } from "@/shared/hooks/useStore";
import { DATE_FORMAT, formatDate } from "@/shared/lib";
import { AccountListStore } from "@/shared/stores/AccountListStore";
import { Transactions } from "@/widgets/Transactions";
import { useGetVerificationInfo } from "@/api/registration";
import { VerificationStatus } from "@/api/registration/types";
import { VerificationLayout } from "@/shared/components/VerificationLayout";
export var TransactionPage = observer(function () {
    var data = useGetVerificationInfo().data;
    var status = data === null || data === void 0 ? void 0 : data.status;
    var accounts = useStoreContext(AccountListStore);
    var _a = React.useState([]), selectedItems = _a[0], setSelectedItems = _a[1];
    var _b = React.useState([]), allItems = _b[0], setAllItems = _b[1];
    var ids = accounts.list.map(function (account) { return account.account; });
    var header = ["Date", "Operation", "Amount"];
    var items = (selectedItems.length > 0 ? selectedItems : allItems).map(function (item) { return [
        formatDate(item.createdAt || "", DATE_FORMAT.date),
        "".concat(item.operation, ", ").concat(item.currency),
        item.change
            ? "".concat(formattedTokenAmount(item.change), " ").concat(item.currency)
            : "",
    ]; });
    var csv = useCsv(header, items);
    var download = useDownload(csv, "transactions", "text/csv");
    return (React.createElement(Box, { flexDirection: "column", gap: "32px", display: "flex" },
        React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center" },
            React.createElement(Typography, { type: "text28Bold" }, "Transactions history"),
            status === VerificationStatus.GREEN && (React.createElement(Button, { size: "s", variant: "LightBlue", icon: "Download", iconSize: 16, padding: "0 25px", onClick: download }, "Export as CSV"))),
        status === VerificationStatus.GREEN ? (React.createElement(Transactions, { accountIds: ids, onSelectedItems: setSelectedItems, tableProps: { checkboxSelection: true }, onItems: setAllItems })) : (React.createElement(VerificationLayout, null))));
});
